<template>
  <div class="lineMenu">
    <div style="padding: 15px 15px 15px 15px;">
      <!-- <Button label="Add Patient" class="p-mr-2 p-button-raised p-button-rounded"/> -->
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({})
export default class LineMenu extends Vue {}
</script>

<style scoped>
.lineMenu {
  background: #1c233f;
  height: 70px;
}

.p-button {
  width: 150px;
  padding: 0px 15px 0px 15px;
  border: none;
  height: 40px;
  background: white;
  border-radius: 20px;
  font-size: 16px;
  color: #2196f3;
  margin: 15px;
}

.p-button:hover {
  background: #2196f3;
  color: white;
}
</style>
